import { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.bg} !important;

    color: ${({ theme }) => theme.colors.text} !important;
  }

  p, .p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 0;
  }

  ul,.ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }


  a {
    transition: all 0.3s ease-out;
    color: ${({ theme }) => theme.colors.heading} !important;
    &:hover, &:active, &:focus{
      text-decoration: none!important;
      outline: none !important;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

    .about > p {
      color: rgba(255,255,255,0.7) !important;
    }

    .about > p > a {
      color: white !important;
    }

    .linktree-icon {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(324deg) brightness(102%) contrast(102%);
    }
    
    .article-body > h1,
    .article-body > h2,
    .article-body > h3,
    .article-body > h4,
    .article-body > h5,
    .article-body > p,
    .article-body > img,
    .pitch-body > h1,
    .pitch-body > h2,
    .pitch-body > h3,
    .pitch-body > h4,
    .pitch-body > h5,
    .pitch-body > p,
    .pitch-body > img {
      margin-bottom: 2rem;
    }

    .article-body > ul,
    .pitch-body > ul {
      list-style: disc;
      margin-bottom: 2rem;
      padding-left: 1rem;
    }

    .inverted-signature {
      filter: invert(1.0);
    }

    .gatsby-resp-image-wrapper {
      max-width: 100% !important;
    }

   .gatsby-resp-image-figcaption {
    color: #56585C;
    font-size: 1rem;
   }
   
   .pitch-body > h1,
   .pitch-body > h2,
   .pitch-body > h3,
   .pitch-body > h4,
   .pitch-body > h5,
   .pitch-body > p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
   }

   .category-subtitle {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1.19px;
    text-transform: uppercase;
   }
`;

export default globalStyle;
