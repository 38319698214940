exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-portfolio-details-alt-js": () => import("./../../../src/templates/portfolio-details-alt.js" /* webpackChunkName: "component---src-templates-portfolio-details-alt-js" */),
  "component---src-templates-portfolio-details-js": () => import("./../../../src/templates/portfolio-details.js" /* webpackChunkName: "component---src-templates-portfolio-details-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

