import React from "react";
import { Container } from "react-bootstrap";
import { Box, Title } from "../Core";
import Social from "../Social";

const Footer = ({ isDark = true, isMitch = true, copy = "" }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box
        bg="bg"
        pt={[0, null, null, "3.75rem"]}
        pb="3.75rem"
        css={`
          z-index: -50;
        `}
      >
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <Title
              variant="card"
              css={`
                font-size: 0.8125rem;
              `}
              className="text-uppercase"
            >
              {copy}
            </Title>
            <Social isFooter isMitch={isMitch} />
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
