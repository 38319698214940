import React from "react";
import { Link } from "gatsby";
import { Title } from "../Core";

const Logo = ({
  color = "front",
  height,
  className = "",
  initials = "mmp",
  ...rest
}) => {
  const primaryColor = process.env.GATSBY_SITE_COLOR || "#585cbf";
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <Title color={color} variant="cardLg" className="mb-0">
        {initials}
        <span style={{ color: primaryColor }}>.</span>
      </Title>
    </Link>
  );
};

export default Logo;
