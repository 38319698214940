import React, { useEffect, useState } from "react";

const GlobalContext = React.createContext();
const themeConfigDefault = {
  headerDark: false,
  bodyDark: false,
  footerDark: false,
};

const GlobalProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeConfigDefault);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const windowGlobal = typeof window !== "undefined" && window;

  useEffect(() => {
    const webDarkMode = windowGlobal.localStorage.getItem("web-dark-mode");
    if (!webDarkMode) {
      windowGlobal.localStorage.setItem("web-dark-mode", theme.bodyDark);
    } else {
      const isDark = webDarkMode === "true";
      setTheme({
        bodyDark: isDark,
        headerDark: isDark,
        footerDark: isDark,
      });
    }
  }, []);

  useEffect(() => {
    windowGlobal.localStorage.setItem("web-dark-mode", theme.bodyDark);
  }, [theme.bodyDark]);

  const changeTheme = (themeConfig = themeConfigDefault) => {
    setTheme({
      ...themeConfig,
    });
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleAbout = () => {
    setAboutVisible(!aboutVisible);
  };

  const closeAbout = () => {
    setAboutVisible(false);
  };

  const toggleContact = () => {
    setContactVisible(!contactVisible);
  };

  const closeContact = () => {
    setContactVisible(false);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme,
        changeTheme,
        videoModalVisible,
        toggleVideoModal,
        aboutVisible,
        toggleAbout,
        closeAbout,
        contactVisible,
        toggleContact,
        closeContact,
        visibleOffCanvas,
        toggleOffCanvas,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
