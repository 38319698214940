import React from "react";
import styled from "styled-components";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
  FaEnvelope,
} from "react-icons/fa";
import { Button } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

import Linktree from "../../assets/linktree.inline.svg";
import LinktreeWhite from "../../assets/linktree-white.inline.svg";

const icons = {
  Linktree: (
    <Linktree
      style={{
        paddingTop: "2px",
        height: "24px",
        width: "24px",
      }}
    />
  ),
  LinktreeWhite: (
    <LinktreeWhite
      style={{
        paddingTop: "2px",
        height: "24px",
        width: "24px",
      }}
    />
  ),
  Instagram: <FaInstagram />,
  Facebook: <FaFacebookF />,
  Twitter: <FaTwitter />,
  LinkedIn: <FaLinkedinIn />,
  Github: <FaGithub />,
  Email: <FaEnvelope />,
};

const SocialContainer = styled.div`
  .btn {
    color: ${({ theme, isFooter }) =>
      isFooter ? theme.colors.socialColor : theme.colors.socialBg} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    background: ${({ theme, isFooter }) =>
      isFooter ? theme.colors.socialBg : theme.colors.socialColor} !important;

    :hover {
      color: ${({ theme }) => theme.primary} !important;
      box-shadow: ${({ theme }) => `0 0 1px 1px ${theme.primary}`};
    }
    &.inverse {
      box-shadow: 0 0 1px 1px #fff;
      :hover {
        color: ${({ theme }) => theme.primary};
      }
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Social = ({
  outline,
  inverse,
  className,
  isFooter = false,
  isMitch = true,
  ...rest
}) => {
  const data = useStaticQuery(graphql`
    query {
      socialMitch: markdownRemark(
        fileAbsolutePath: { regex: "//cms/social-mitch/" }
      ) {
        frontmatter {
          social_linktree
          social_instagram
          social_facebook
          social_twitter
          social_linkedin
          social_github
          social_email
        }
      }
      socialSteph: markdownRemark(
        fileAbsolutePath: { regex: "//cms/social-stephanie/" }
      ) {
        frontmatter {
          social_linktree
          social_instagram
          social_facebook
          social_twitter
          social_linkedin
          social_github
          social_email
        }
      }
    }
  `);
  const dataSource = isMitch
    ? data.socialMitch.frontmatter
    : data.socialSteph.frontmatter;

  const items = [
    {
      title: "Linktree",
      url: dataSource.social_linktree,
    },
    {
      title: "Instagram",
      url: dataSource.social_instagram,
    },
    {
      title: "Facebook",
      url: dataSource.social_facebook,
    },
    {
      title: "Twitter",
      url: dataSource.social_twitter,
    },
    {
      title: "LinkedIn",
      url: dataSource.social_linkedin,
    },
    {
      title: "Github",
      url: dataSource.social_github,
    },
    {
      title: "Email",
      url: `mailto:${dataSource.social_email}`,
    },
  ];
  return (
    <SocialContainer
      isFooter={isFooter}
      className={`d-flex ${className}`}
      {...rest}
    >
      {items
        .filter((item) => item.url && item.url.trim() !== "")
        .map((item) => (
          <div key={item.title}>
            <Button
              aria-label={item.title}
              variant={"outline-primary"}
              size="lg"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className={inverse && "inverse"}
            >
              {item.title === "Linktree" && isFooter
                ? icons["LinktreeWhite"]
                : icons[item.title]}
            </Button>
          </div>
        ))}
    </SocialContainer>
  );
};

export default Social;
